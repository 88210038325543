import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Skeleton,
  Text,
  Textarea,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Client } from "api/axios";
import ConfirmationModal from "components/modals/Confirmation";
import React, { useState } from "react";
import {
  MdAttachMoney,
  MdCheck,
  MdClose,
  MdEdit,
  MdFileOpen,
  MdFlag,
  MdManageSearch,
  MdMoveDown,
  MdOutgoingMail,
  MdPayments,
} from "react-icons/md";
import { toast } from "react-toastify";
import { ACTION_TYPES, PAYMENT_PURPOSE, STATUS_TYPES } from "../../constants";
import { useForm } from "react-hook-form";
import PayoutOptions from "../PayoutOptions";

async function patchPayoutStatus({
  id,
  status,
  payoutOptions,
  message,
  emailVersion,
}) {
  let data = { status };
  if (message) {
    data.message = message;
  }
  if (payoutOptions) {
    data.payoutOptions = payoutOptions;
  }
  if (emailVersion) {
    data.emailVersion = emailVersion;
  }
  return await Client(true).patch(
    `/api/payouts/${id}/update-status`,
    JSON.stringify(data)
  );
}

async function patchPayoutAmount({ id, message, amount }) {
  return await Client(true).patch(
    `/api/payouts/${id}`,
    JSON.stringify({ message, amount })
  );
}

async function generateInvoice(form) {
  return await Client(true).get(`/api/payouts/${form.id}/invoice`);
}

const ActionButton = ({
  title,
  action,
  onOpen,
  sendEmailIcon = false,
  icon,
  disableActions,
  ...props
}) => (
  <Button
    isDisabled={disableActions}
    size="xs"
    w="stretch"
    display={"flex"}
    gap={4}
    px={4}
    py={3}
    textTransform={"uppercase"}
    onClick={() => {
      if (onOpen) {
        onOpen();
      }
      if (action) {
        action();
      }
    }}
    border="1px"
    borderColor="#0A1129"
    {...props}
  >
    <Box w="8px">{icon ? icon : <></>}</Box>
    <Text w="full">{title}</Text>
    <Box w="8px" alignItems={"center"}>
      {sendEmailIcon ? <MdOutgoingMail color={"#7551FF"} /> : <></>}
    </Box>
  </Button>
);

export default function Actions({ actions, paymentData }) {
  const queryClient = useQueryClient();

  const approveDisclosure = useDisclosure();
  const editPaymentAmountDisclosure = useDisclosure();
  const flagDisclosure = useDisclosure();
  const fundsDisclosure = useDisclosure();
  const reviewDisclosure = useDisclosure();
  const addReviewDisclosure = useDisclosure();
  const moveToCompletedDisclosure = useDisclosure();
  const moveToRequestedDisclosure = useDisclosure();
  const rejectDisclosure = useDisclosure();
  const reprocessDisclosure = useDisclosure();
  const resendFormDisclosure = useDisclosure();
  const viewInvoiceDisclosure = useDisclosure();
  const [invoice, setInvoice] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoiceError, setInvoiceError] = useState(null);

  const statusMutation = useMutation({
    mutationFn: patchPayoutStatus,
    onSuccess: async ({ data }) => {
      refetchOnAction();
      if (data.message) {
        toast.success(data.message);
      } else {
        toast.success(`Payout status updated successfully!`);
      }

      data.warnings?.forEach((warning) => {
        toast.warning(warning);
      });
    },
  });

  const disableActions = React.useMemo(
    () => statusMutation.isLoading || statusMutation.isSuccess,
    [statusMutation.isLoading, statusMutation.isSuccess]
  );
  const amountMutation = useMutation({
    mutationFn: patchPayoutAmount,
    onSuccess: () => {
      refetchOnAction();
      toast.success("Payout amount updated successfully!");
    },
  });

  const refetchOnAction = () => {
    queryClient.invalidateQueries({
      queryKey: ["/api/payouts/"],
    });
  };

  async function updatePayoutStatus(action, message, payoutOptions) {
    switch (action) {
      case ACTION_TYPES.APPROVE:
        statusMutation.mutate({
          id: paymentData.id,
          status: STATUS_TYPES.UNUSED.API_KEY,
          payoutOptions,
        });
        break;
      case ACTION_TYPES.FLAG:
        statusMutation.mutate({
          id: paymentData.id,
          status: STATUS_TYPES.FLAGGED.API_KEY,
        });
        break;
      case ACTION_TYPES.FUNDS_SENT:
        statusMutation.mutate({
          id: paymentData.id,
          status: STATUS_TYPES.FINALIZED.API_KEY,
          emailVersion: message,
        });
        break;
      case ACTION_TYPES.REJECT:
        statusMutation.mutate({
          id: paymentData.id,
          status: STATUS_TYPES.REJECTED.API_KEY,
        });
        break;
      case ACTION_TYPES.MOVE_TO_COMPLETED:
        statusMutation.mutate({
          id: paymentData.id,
          status: STATUS_TYPES.FINALIZED.API_KEY,
        });
        break;
      case ACTION_TYPES.MOVE_TO_REQUESTED:
        statusMutation.mutate({
          id: paymentData.id,
          status: STATUS_TYPES.REQUEST.API_KEY,
        });
        break;
      case ACTION_TYPES.UNUSED:
        statusMutation.mutate({
          id: paymentData.id,
          status: STATUS_TYPES.UNUSED.API_KEY,
        });
        break;
      case ACTION_TYPES.MOVE_TO_REVIEW:
        statusMutation.mutate({
          id: paymentData.id,
          status: STATUS_TYPES.REVIEW.API_KEY,
        });
        break;
      case ACTION_TYPES.REVIEW:
        statusMutation.mutate({
          id: paymentData.id,
          status: STATUS_TYPES.REQUEST.API_KEY,
          message,
        });
        break;
      case ACTION_TYPES.REPROCESS:
        statusMutation.mutate({
          id: paymentData.id,
          status: STATUS_TYPES.REQUEST.API_KEY,
          message,
        });
        break;
      default:
        console.error("Invalid action type", action);
        break;
    }
  }

  function Approve({ isOpen, onOpen, onClose }) {
    const onModalConfirmation = (d) => {
      const payoutOptions = d.payoutOptions.map((option) => option.value);
      updatePayoutStatus(ACTION_TYPES.APPROVE, "", payoutOptions);
    };
    const { handleSubmit, control } = useForm();

    return (
      <>
        <ActionButton
          title={ACTION_TYPES.APPROVE}
          disableActions={disableActions}
          icon={<MdCheck color={"#7551FF"} />}
          onOpen={onOpen}
          sendEmailIcon={true}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmit(onModalConfirmation)} noValidate>
              <ModalHeader pr={10}>Approve Payout</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack gap={2} px={0} alignItems={"flex-start"}>
                  <Text>Are you sure you want to approve this payout?</Text>
                  <Divider my={2} />
                  <PayoutOptions control={control} />
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" colorScheme={"green"}>
                  <Text>{ACTION_TYPES.APPROVE}</Text>
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  }

  function Reject({ isOpen, onOpen, onClose }) {
    const onModalConfirmation = () => updatePayoutStatus(ACTION_TYPES.REJECT);

    return (
      <>
        <ActionButton
          title={ACTION_TYPES.REJECT}
          disableActions={disableActions}
          onOpen={onOpen}
          icon={<MdClose color={"#7551FF"} />}
        />
        <ConfirmationModal
          header={{ children: "Reject Payout" }}
          body={{
            children: "Are you sure you want to reject this payout?",
          }}
          footer={{
            action: {
              text: ACTION_TYPES.REJECT,
              props: { colorScheme: "red" },
              onConfirmation: onModalConfirmation,
            },
          }}
          disclosure={{ isOpen, onClose }}
        />
      </>
    );
  }

  function Reprocess({ isOpen, onOpen, onClose }) {
    const onModalConfirmation = () =>
      updatePayoutStatus(ACTION_TYPES.REPROCESS);

    return (
      <>
        <ActionButton
          title={ACTION_TYPES.REPROCESS}
          disableActions={disableActions}
          onOpen={onOpen}
          icon={<MdClose color={"#7551FF"} />}
        />
        <ConfirmationModal
          header={{ children: "Reprocess Payout" }}
          body={{
            children: "Are you sure you want to reprocess this payout?",
          }}
          footer={{
            action: {
              text: ACTION_TYPES.REPROCESS,
              props: { colorScheme: "red" },
              onConfirmation: onModalConfirmation,
            },
          }}
          disclosure={{ isOpen, onClose }}
        />
      </>
    );
  }

  function Review({ isOpen, onOpen, onClose }) {
    const onModalConfirmation = () =>
      updatePayoutStatus(ACTION_TYPES.MOVE_TO_REVIEW);

    return (
      <>
        <ActionButton
          title={ACTION_TYPES.MOVE_TO_REVIEW}
          disableActions={disableActions}
          onOpen={onOpen}
          colorScheme="orange"
          icon={<MdManageSearch color={"#7551FF"} />}
        />
        <ConfirmationModal
          header={{ children: "Review Payout" }}
          body={{
            children: "Are you sure you want to review this payout?",
          }}
          footer={{
            action: {
              text: ACTION_TYPES.MOVE_TO_REVIEW,
              props: { colorScheme: "orange" },
              onConfirmation: onModalConfirmation,
            },
          }}
          disclosure={{ isOpen, onClose }}
        />
      </>
    );
  }

  function ResendForm({ isOpen, onOpen, onClose }) {
    const onModalConfirmation = () => updatePayoutStatus(ACTION_TYPES.UNUSED);

    return (
      <>
        <ActionButton
          title={ACTION_TYPES.RESEND_FORM}
          disableActions={disableActions}
          onOpen={onOpen}
          icon={<MdClose color={"#7551FF"} />}
        />
        <ConfirmationModal
          header={{ children: "Resend Payout Email" }}
          body={{
            children: "Are you sure you want to resend email to customer?",
          }}
          footer={{
            action: {
              text: ACTION_TYPES.RESEND_FORM,
              props: { colorScheme: "green" },
              onConfirmation: onModalConfirmation,
            },
          }}
          disclosure={{ isOpen, onClose }}
        />
      </>
    );
  }

  function ViewInvoice({ isOpen, onOpen, onClose }) {
    const on_click = async () => {
      setInvoiceLoading(true);
      onOpen();
      try {
        const generatedInvoice = await generateInvoice(paymentData);
        setInvoice(generatedInvoice.data);
        setInvoiceError(null);
      } catch (err) {
        setInvoiceError(err);
      } finally {
        setInvoiceLoading(false);
      }
    };

    return (
      <>
        <ActionButton
          title={ACTION_TYPES.VIEW_INVOICE}
          disableActions={disableActions}
          action={on_click}
          icon={<MdFileOpen color={"#7551FF "} />}
        />
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent h="800px" maxH="800px" maxW="1050px">
            <ModalHeader>Invoice Viewer</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Skeleton isLoaded={!invoiceLoading}>
                {invoiceError ? (
                  <Alert status="error">
                    <AlertIcon />
                    {invoiceError.message}
                  </Alert>
                ) : (
                  <iframe
                    title="invoice"
                    src={`data:application/pdf;base64, ${invoice}`}
                    style={{ width: "1024px", height: "720px" }}
                  ></iframe>
                )}
              </Skeleton>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  function FundsSent({ isOpen, onOpen, onClose, purpose }) {
    const onModalConfirmation = (formControlState) => {
      updatePayoutStatus(
        ACTION_TYPES.FUNDS_SENT,
        formControlState.emailVersion
      );
    };

    const isAffiliate = purpose === PAYMENT_PURPOSE.Affiliate;
    return (
      <>
        <ActionButton
          title={ACTION_TYPES.FUNDS_SENT}
          disableActions={disableActions}
          sendEmailIcon={true}
          onOpen={onOpen}
          icon={<MdPayments color={"#7551FF"} />}
        />
        <ConfirmationModal
          header={{ children: "Confirm Funds Sent to Client" }}
          body={{
            children: [
              "Are you sure that this payout has been sent? This action will move the payout to the payout history.",
              "Select version of payment complete email to send to client",
              <RadioGroup
                name={"emailVersion"}
                key={"emailVersion"}
                rules={{ required: "Required Field" }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
                mt={2}
                defaultValue={"v1"}
              >
                {/* add none option to force ops to make the decision */}
                <Radio value="none">No Email</Radio>
                {
                  <Radio value="v1">
                    {isAffiliate ? `Funds Sent Email` : `TP & Discord`}
                  </Radio>
                }
                {!isAffiliate && <Radio value="v2">TP & Discord & PFM</Radio>}
                {!isAffiliate && <Radio value="v3">No Review Links</Radio>}
              </RadioGroup>,
            ],
          }}
          footer={{
            action: {
              text: ACTION_TYPES.FUNDS_SENT,
              props: { colorScheme: "green" },
              onConfirmation: onModalConfirmation,
            },
          }}
          disclosure={{ isOpen, onClose }}
        />
      </>
    );
  }

  function MoveToCompleted({ isOpen, onOpen, onClose }) {
    const onModalConfirmation = () =>
      updatePayoutStatus(ACTION_TYPES.MOVE_TO_COMPLETED);

    return (
      <>
        <ActionButton
          title={ACTION_TYPES.MOVE_TO_COMPLETED}
          disableActions={disableActions}
          onOpen={onOpen}
          icon={<MdMoveDown color={"#7551FF"} />}
        />
        <ConfirmationModal
          header={{ children: "Move to Completed" }}
          body={{
            children: "Are you sure you want to move this payout to history?",
          }}
          footer={{
            action: {
              text: ACTION_TYPES.MOVE_TO_COMPLETED,
              props: { colorScheme: "green" },
              onConfirmation: onModalConfirmation,
            },
          }}
          disclosure={{ isOpen, onClose }}
        />
      </>
    );
  }

  function MoveToRequested({ isOpen, onOpen, onClose }) {
    const onModalConfirmation = () =>
      updatePayoutStatus(ACTION_TYPES.MOVE_TO_REQUESTED);

    return (
      <>
        <ActionButton
          title={ACTION_TYPES.MOVE_TO_REQUESTED}
          disableActions={disableActions}
          onOpen={onOpen}
          icon={<MdMoveDown color={"#7551FF"} />}
        />
        <ConfirmationModal
          header={{ children: "Move to Requested" }}
          body={{
            children: "Are you sure you want to move this to requested status?",
          }}
          footer={{
            action: {
              text: ACTION_TYPES.MOVE_TO_REQUESTED,
              props: { colorScheme: "green" },
              onConfirmation: onModalConfirmation,
            },
          }}
          disclosure={{ isOpen, onClose }}
        />
      </>
    );
  }

  function Flag({ isOpen, onOpen, onClose }) {
    const onModalConfirmation = () => updatePayoutStatus(ACTION_TYPES.FLAG);

    return (
      <>
        <ActionButton
          title={ACTION_TYPES.FLAG}
          disableActions={disableActions}
          onOpen={onOpen}
          icon={<MdFlag color={"#7551FF"} />}
        />
        <ConfirmationModal
          header={{ children: "Flag Payout" }}
          body={{
            children: "Are you sure you want to flag this payout?",
          }}
          footer={{
            action: {
              text: ACTION_TYPES.FLAG,
              props: { colorScheme: "yellow" },
              onConfirmation: onModalConfirmation,
            },
          }}
          disclosure={{ isOpen, onClose }}
        />
      </>
    );
  }

  function EditPaymentAmount({ isOpen, onOpen, onClose }) {
    const onModalConfirmation = (formControlState) =>
      amountMutation.mutate({
        id: paymentData.id,
        message: formControlState.message,
        amount: formControlState.amount,
      });

    return (
      <>
        <ActionButton
          title={ACTION_TYPES.EDIT_PAYMENT_AMOUNT}
          disableActions={disableActions}
          onOpen={onOpen}
          icon={<MdEdit color={"#7551FF"} />}
        />
        <ConfirmationModal
          header={{ children: "Modify Payment Amount" }}
          body={{
            children: [
              "Please modify the amount below and provide a reason why the payout amount has been changed. You can't undo this action afterwards",
              <Textarea
                key="message"
                name="message"
                placeholder={"Additional Notes..."}
                rules={{ required: "Required Field" }}
              />,
              <NumberInput
                name="amount"
                key={"amount"}
                w="full"
                min={0}
                size="lg"
                precision={2}
                step={10}
                allowMouseWheel
                rules={{
                  required: "Required Field",
                  validate: (value) =>
                    Number(value) !== paymentData.amount ||
                    "The submitted number must not be equal to the current amount",
                }}
              >
                <InputGroup>
                  <InputLeftElement pointerEvents="none" height="stretch">
                    <MdAttachMoney />
                  </InputLeftElement>
                  <NumberInputField
                    color="white"
                    px={"32px"}
                    placeholder={paymentData.amount}
                    fontWeight="500"
                  />
                </InputGroup>

                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>,
            ],
          }}
          footer={{
            action: {
              text: "Save",
              props: { colorScheme: "green" },
              onConfirmation: onModalConfirmation,
            },
          }}
          disclosure={{ isOpen, onClose }}
        />
      </>
    );
  }

  function AddReview({ isOpen, onOpen, onClose }) {
    const onModalConfirmation = (formControlState) =>
      updatePayoutStatus(ACTION_TYPES.REVIEW, formControlState.message);

    return (
      <>
        <ActionButton
          title={ACTION_TYPES.REVIEW}
          disableActions={disableActions}
          onOpen={onOpen}
          colorScheme="orange"
          icon={<MdManageSearch color={"#7551FF"} />}
        />
        <ConfirmationModal
          header={{ children: "Add Payment Review" }}
          body={{
            children: [
              "Please provide a review.",
              <Textarea
                key="message"
                name="message"
                placeholder={"Additional Notes..."}
                rules={{ required: "Required Field" }}
              />,
            ],
          }}
          footer={{
            action: {
              text: "Save",
              props: { colorScheme: "green" },
              onConfirmation: onModalConfirmation,
            },
          }}
          disclosure={{ isOpen, onClose }}
        />
      </>
    );
  }

  return actions ? (
    <VStack w="fit-content">
      {actions.includes(ACTION_TYPES.APPROVE) && Approve(approveDisclosure)}
      {actions.includes(ACTION_TYPES.MOVE_TO_COMPLETED) &&
        MoveToCompleted(moveToCompletedDisclosure)}
      {actions.includes(ACTION_TYPES.MOVE_TO_REQUESTED) &&
        MoveToRequested(moveToRequestedDisclosure)}
      {actions.includes(ACTION_TYPES.EDIT_PAYMENT_AMOUNT) &&
        EditPaymentAmount(editPaymentAmountDisclosure)}
      {actions.includes(ACTION_TYPES.FLAG) && Flag(flagDisclosure)}
      {actions.includes(ACTION_TYPES.REJECT) && Reject(rejectDisclosure)}
      {actions.includes(ACTION_TYPES.REPROCESS) &&
        Reprocess(reprocessDisclosure)}

      {actions.includes(ACTION_TYPES.VIEW_INVOICE) &&
        ViewInvoice(viewInvoiceDisclosure)}
      {actions.includes(ACTION_TYPES.FUNDS_SENT) &&
        FundsSent({ ...fundsDisclosure, purpose: paymentData.purpose })}

      {actions.includes(ACTION_TYPES.RESEND_FORM) &&
        ResendForm(resendFormDisclosure)}
      {actions.includes(ACTION_TYPES.MOVE_TO_REVIEW) &&
        Review(reviewDisclosure)}
      {actions.includes(ACTION_TYPES.REVIEW) && AddReview(addReviewDisclosure)}
    </VStack>
  ) : (
    <>NO ACTIONS CONFIGURED</>
  );
}
